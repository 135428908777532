import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import ProjectTeam from './project-team';
import IndustrySuccess from './industry-success';
import OurPartners from './our-partners';
import Brands from './brands';
import Footer from './footer';
import logoSymbol from '../../assets/zagalogo-dark-8.svg';

import './styles.scss';

const Form = (props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const redirectToCalendy = () => {
    window.location.href = 'https://calendly.com/zagameet/30min';
  };

  useEffect(() => {
    if (props.match.params.scheduled) {
      setShowConfirmation(true);
    }
  }, [props.match.params.scheduled]);

  const renderButtonOrConfirmation = () => {
    if (!showConfirmation) {
      return (
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <Button
              variant='contained'
              className='schedule-button'
              onClick={redirectToCalendy}
            >
              Schedule My Meet and Greet
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <h1 className='form-title orange bottom'>Thank you!</h1>
            <span className='form-title little'>
              Your Meet and Greet Has Been Scheduled.
            </span>
          </Grid>
        </Grid>
      );
    }
  };

  const renderHeader = () => {
    return (
      <>
        <Grid container className='header'>
          <Grid item xs={12} sm={12} md={12}>
            <div className='logo-container'>
              <img src={logoSymbol} alt='zaga logo'></img>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            container
            style={{ position: 'relative', justifyContent: 'flex-end' }}
          >
            <div className='form-container'>
              <Grid item xs={12} container>
                <Grid item xs={12} sm={12} md={12}>
                  <h1 className='form-title'>
                    Launch. Innovate. <span className='orange'>Optimize.</span>
                  </h1>

                  <span className='form-title little'>
                    Enhance Your Technology Team Today with Zaga.
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <form noValidate autoComplete='off'>
                    <Grid item xs={12} container>
                      {renderButtonOrConfirmation()}
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderBody = () => {
    return (
      <>
        <Grid container spacing={3} className='body'>
          <Grid item xs={12} sm={12} md={12}>
            <div className='intro-container'>
              <h2 className='body-intro'>
                With over 125 technology and design experts across Latin America
                and the US, Zaga delivers the unique advantage of agility and
                caliber to innovate your digital business at light speed.
              </h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} container className='specifications'>
            <Grid item xs={12} sm={4}>
              <div className='specification-container'>
                <h3 className='spec-title'>Technology Expertise</h3>
                <ul>
                  <li>
                    <CheckCircleIcon />
                    Drupal
                  </li>
                  <li>
                    <CheckCircleIcon />
                    E-commerce
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Shopify
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Tableau
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Amazon & AWS
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Alteryx
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className='specification-container'>
                <h3 className='spec-title'>Roles We Fill</h3>
                <ul>
                  <li>
                    <CheckCircleIcon />
                    Front end
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Back end
                  </li>
                  <li>
                    <CheckCircleIcon />
                    App
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Mobile
                  </li>
                  <li>
                    <CheckCircleIcon />
                    API & Integration
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className='specification-container'>
                <h3 className='spec-title'>Team-Focused Collaboration</h3>
                <ul>
                  <li>
                    <CheckCircleIcon />
                    Dedicated HR support
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Project and Pipeline Management
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Agile/Scrum Leaders
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Innovation Strategy
                  </li>
                  <li>
                    <CheckCircleIcon />
                    Marketing/Creative Expertise
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div className='main-container'>
      {renderHeader()}
      {renderBody()}
      <ProjectTeam />
      <IndustrySuccess />
      <OurPartners />
      <Brands />
      <Footer logoSymbol={logoSymbol} />
    </div>
  );
};

export default Form;
