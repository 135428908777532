import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import Form from './components/form/index';
import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <Route path='/:scheduled?' component={Form} />
    </BrowserRouter>
  );
}

export default App;
