import React from 'react';
import Grid from '@material-ui/core/Grid';
import maria from '../../assets/maria.png';
import santiago from '../../assets/0.png';

const ProjectTeam = () => {
  return (
    <Grid container spacing={3} className='project-tech-container'>
      <Grid item xs={12} sm={12}>
        <div className='project-title'>
          <h2>Curate Your Perfect Tech Team</h2>
        </div>
      </Grid>
      {/* <Grid item xs={12} sm={12} container> */}
      <div className='team-container team'>
        <Grid item xs={12} sm={12} container>
          <Grid item xs={12} sm={6}>
            <img src={maria} alt='Headshot of Maria P.' title='Maria P.' />
            <br></br>
            <h3>Maria P.</h3>
            <div className='team-text'>
              Your technology and HR partner, Maria, will work with you to
              assemble, manage, and enhance your development team to deliver the
              best possible performance and scope for each stage of your build.
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              src={santiago}
              alt='Headshot of Santiago A.'
              title='Santiago A.'
            />
            <br></br>
            <h3>Santiago A.</h3>
            <div className='team-text'>
              Senior developer and technology leader, Santiago will employ his
              rich knowledge and experience in strategy, innovation, and
              pipeline management to ensure the highest quality deliverables.
            </div>
          </Grid>
        </Grid>
      </div>
      {/* </Grid> */}
    </Grid>
  );
};

export default ProjectTeam;
