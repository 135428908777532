import React from 'react';
import Grid from '@material-ui/core/Grid';

const Footer = (props) => {
  return (
    <div className='footer-container'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={3}>
          <img src={props.logoSymbol} alt='zaga logo'></img>
          <p>
            We make nearshore <br />
            feel <span>like nextdoor.</span>
          </p>
        </Grid>
        <Grid item xs={12} sm={3} className='text-bottom-container'>
          <div className='text-bottom top'>
            <span>
              {' '}
              <a
                href='https://zaga.dev/privacy-policy/'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </a>
            </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className='text-bottom-container'>
          <div className='text-bottom bottom'>
            <span>© 2020 Zaga Labs, LLC</span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
