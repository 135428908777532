import React from 'react';
import Grid from '@material-ui/core/Grid';
import bulb from '../../assets/bulb.svg';
import rocket from '../../assets/rocket.svg';
import calendar from '../../assets/calendar.svg';

const IndustrySuccess = () => {
  return (
    <Grid container spacing={3} className='project-tech-container'>
      <Grid item xs={12} sm={12}>
        <div className='project-title industry'>
          <h2>Industry-Proven Success Approach</h2>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} container>
        <div className='team-container industry'>
          <Grid item xs={12} sm={12} container spacing={10} className='logos'>
            <Grid item xs={12} sm={4}>
              <img
                src={bulb}
                alt='Lightbulb with wrench illustrated icon'
                title='Innovate and build icon'
              />
              <br></br>
              <h3>Innovate and Build</h3>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src={rocket}
                alt='Rocket ship with magnifying glass illustrated icon'
                title='Launch and learn icon'
              />
              <br></br>
              <h3>Launch and Learn</h3>
            </Grid>
            <Grid item xs={12} sm={4}>
              <img
                src={calendar}
                alt='Calendar illustrated icon'
                title='Optimize icon'
              />
              <br></br>
              <h3>Optimize in Days, Not Months</h3>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default IndustrySuccess;
