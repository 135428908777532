import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import laptop from '../../assets/laptop-jj.png';

const OurPartners = () => {
  const redirectToCalendy = () => {
    window.location.href = 'https://calendly.com/zagameet/30min';
  };

  return (
    <Grid container spacing={3} className='project-tech-container'>
      <Grid item xs={12} sm={12}>
        <div className='project-title partners'>
          <h2>Our Partners</h2>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} container>
        <div className='team-container partners'>
          <Grid item xs={12} sm={12} container spacing={3} className='logos'>
            <Grid item xs={12} sm={6}>
              <img
                src={laptop}
                alt='Laptop with website on screen'
                title='Johnson & Johnson project comp'
              />
            </Grid>
            <Grid item xs={12} sm={6} container>
              <Grid item xs={12} sm={12} className='right-partners-container'>
                <h3 className='partner-title'>Johnson & Johnson</h3>
                <div className='team-text'>
                  Based on a critical business need to beat a competitor to
                  market, Zaga collaborated with J&J’s IT leadership to reduce
                  development timeline from 6 months to 30 days.
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                container
                className='partners-container-button'
              >
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    className='schedule-button'
                    onClick={redirectToCalendy}
                  >
                    Build your team today!
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default OurPartners;
