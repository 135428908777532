import React from 'react';
import Grid from '@material-ui/core/Grid';
import johnson from '../../assets/jnj-logo.png';
import toyota from '../../assets/toyota-logo.png';
import today from '../../assets/todaytix-logo.png';
import ocean from '../../assets/oceanx-logo.png';
import puppy from '../../assets/puppyspot-logo.png';
import bendr from '../../assets/bendr-logo.png';
import cypress from '../../assets/ccr-logo.png';
import kitchen from '../../assets/kitchenunited-logo.png';
import eye from '../../assets/eyemoji-logo.png';

const Brands = () => {
  return (
    <div className='brands-container'>
      <Grid
        container
        spacing={3}
        className='project-tech-container'
        direction='row'
        justify='center'
        alignItems='center'
      >
        <Grid item xs={12} sm={12} container spacing={3} className='logos'>
          <Grid item xs={12} sm={4}>
            <img
              src={johnson}
              alt='Johnson & Johnson logo'
              title='Johnson & Johnson logo'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={toyota} alt='Toyota logo' title='Toyota logo' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={today} alt='Today Tix logo' title='Today Tix logo' />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} container spacing={3} className='logos'>
          <Grid item xs={12} sm={4}>
            <img src={ocean} alt='Ocean X logo' title='Ocean X logo' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={puppy} alt='Puppy Spot logo' title='Puppy Spot logo' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={bendr} alt='Bendr logo' title='Bendr logo' />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} container spacing={3} className='logos'>
          <Grid item xs={12} sm={4}>
            <img
              src={cypress}
              alt='Cypress Creek Renewables logo'
              title='Cypress Creek Renewables logo'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <img
              src={kitchen}
              alt='Kitchen United logo'
              title='Kitchen United logo'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <img src={eye} alt='Eyemoji logo' title='Eyemoji logo' />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Brands;
